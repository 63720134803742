import React from 'react';
import EmployeesTable from 'components/EmployeesTable';
import { ProfileDetails } from 'components/ProfileDetails/ProfileDetails';
import { EmployeeActions } from 'components/EmployeeActions/EmployeeActions';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { getEmployee } from 'queries/employee';
import { withAuthRouteProtection } from 'hoc/withAuthRouteProtection/withAuthRouteProtection';
import { AdminWrapper } from '../../features/admin/components/AdminWrapper/AdminWrapper';

export const Results = styled.div`
  display: flex;
`;

const ProductRepresentatives: React.FunctionComponent = () => {
  const [fetchEmployee, { loading, data }] = useLazyQuery(getEmployee);

  const handleGetEmployee = (employeeId: string): void => {
    fetchEmployee({
      variables: {
        employeeId,
      },
    });
  };

  return (
    <AdminWrapper>
      <EmployeeActions />
      <Results>
        <EmployeesTable getEmployee={handleGetEmployee} />
        <ProfileDetails loading={loading} data={data} />
      </Results>
    </AdminWrapper>
  );
};

export default withAuthRouteProtection(ProductRepresentatives, 'buAdmin');
